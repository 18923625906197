<template>
  <svg
    width="12"
    height="16"
    viewBox="0 0 12 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M11.5 5.5C11.5 2.4625 9.0375 0 6 0C2.9625 0 0.5 2.4625 0.5 5.5C0.5 8.2825 2.56813 10.575 5.25 10.9438V12.25H3.75C3.3375 12.25 3 12.5875 3 13C3 13.4125 3.33594 13.75 3.75 13.75H5.25V15.25C5.25 15.6656 5.5875 16 6 16C6.4125 16 6.75 15.6641 6.75 15.25V13.75H8.25C8.66406 13.75 9 13.4141 9 13C9 12.5859 8.66406 12.25 8.25 12.25H6.75V10.9431C9.43125 10.575 11.5 8.28125 11.5 5.5ZM2 5.5C2 3.29437 3.79437 1.5 6 1.5C8.20563 1.5 10 3.29437 10 5.5C10 7.70563 8.20563 9.5 6 9.5C3.79375 9.5 2 7.70625 2 5.5Z" />
  </svg>
</template>
